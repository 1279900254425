import Carousel from 'react-bootstrap/Carousel';
import Gambar1 from "../images/triyasadepan1.jpg";
import Gambar2 from "../images/triyasadepan2.jpg";
import Gambar3 from "../images/triyasadepan3.jpg";
import "../style/main.css"


function DarkVariantExample() {
  return (
    <Carousel
      data-bs-theme="dark" className='pb-5'
    >
      <Carousel.Item >
        <img
          className="d-block w-100"
          src={Gambar1}
          alt="First slide"
        />
        <div className="overlay d-block w-100"></div>
        <Carousel.Caption className="py-auto" >
          <h1 className="d-flex align-items-center justify-content-center" style={{ color: "white", fontSize: 'clamp(20px, 5vw, 150px)' }}>Selamat Datang Di<br/> SMP TRIYASA</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Gambar2}
          alt="Second slide"
        />
        <div className="overlay d-block w-100"></div>
        <Carousel.Caption>
        <h1 className="d-flex align-items-center justify-content-center" style={{ color: "white", fontSize: 'clamp(20px, 5vw, 150px)' }}>Selamat Datang Di<br/> SMP TRIYASA</h1>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={Gambar3}
          alt="Third slide"
        />
        <div className="overlay d-block w-100"></div>
        <Carousel.Caption>
        <h1 className="d-flex align-items-center justify-content-center" style={{ color: "white", fontSize: 'clamp(20px, 5vw, 150px)' }}>Selamat Datang Di<br/> SMP TRIYASA</h1>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default DarkVariantExample;
