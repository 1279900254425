import React from 'react';
import { Nav, Navbar } from "react-bootstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer_background" style={{ backgroundImage: "url(images/footer_background.png)" }}></div>
      <div className="container">
        <div className="row footer_row">
          <div className="col">
            <div className="footer_content">
              <div className="row">

                <div className="col-lg-3 footer_col">
                  {/* Footer About */}
                  <div className="footer_section footer_about">
                    <div className="footer_logo_container">
                      <a href="#">
                        <div className="footer_logo_text">SMP TRIYASA<span> BANDUNG</span></div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 footer_col">
                  {/* Footer Contact */}
                  <div className="footer_section footer_contact">
                    <div className="footer_title">Hubungi Kami</div>
                    <div className="footer_contact_info">
                      <ul>
                        <li><a href="mailto:admin@smptriyasabdg.sch.id" style={{ color: "white" }}>admin@smptriyasabdg.sch.id</a></li>
                        <li>(022) 7800422</li>
                        <li><a href="https://maps.app.goo.gl/DDQvXrkDoHAcAg5e8" style={{ color: "white" }}>Jl. Nagrog No.09 Ujungberung Bandung Jawa Barat Indonesia </a></li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 footer_col">
                  {/* Footer links */}
                  <div className="footer_section footer_links">
                    <div className="footer_title">Menu</div>
                    <div className="footer_links_container">
                      <ul>
                        <li><Nav.Link href={"/"}>Home</Nav.Link></li>
                        <li><Nav.Link href={"/profilSekolah"}>Profil</Nav.Link></li>
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row copyright_row">
          <div className="col">
            <div className="copyright d-flex flex-lg-row flex-column align-items-center justify-content-start">
              <div className="cr_text">
                Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserve
              </div>
              <div className="ml-lg-auto cr_links">
                <ul className="cr_list">
                  <li><a href="#">Copyright notification</a></li>
                  <li><a href="#">Terms of Use</a></li>
                  <li><a href="#">Privacy Policy</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
