import React from 'react';
import "../style/main.css";
import "../style/responsive.css";
import KepalaSekolah from "../images/kepalasekolah.jpg"
import Kesiswaan from "../images/guru-Hj-NINA.jpg"
import Kurikulum2 from "../images/guru-AAM-KURNIASIH.jpg"
import kurikulum from "../images/guru-HIDAYAT.jpg"


const Profile = () => {
  return (
    <div className="card feature mb-3">
    <div className="team">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section_title_container text-center">
              <h2 className="section_title">KEPALA SEKOLAH DAN STAF</h2>
            </div>
          </div>
        </div>
        <div className="row team_row">

          {/* Team Item 1 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={KepalaSekolah} alt="kepala sekolah" /></div>
              <div className="team_body">
                <div className="team_title pb-3 px-2"><a href="#">H. ATE SUPRIATNA, S.Pd.I</a></div>
                <div className="team_subtitle">Kepala Sekolah</div>
              </div>
            </div>
          </div>

          {/* Team Item 2 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={kurikulum} alt="wakasek urusan kurikulum" /></div>
              <div className="team_body">
                <div className="team_title pb-4 px-2"><a href="#">Drs. HIDAYAT DANAS<br/></a></div>
                <div className="team_subtitle">Wakasek Urusan Kurikulum<br/>Guru Matematika</div>
              </div>
            </div>
          </div>

          {/* Team Item 3 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Kurikulum2} alt="wakases urusan kurikulum" /></div>
              <div className="team_body">
                <div className="team_title  px-2"><a href="#">AAM KURNIASIH, S.Pd</a></div>
                <div className="team_subtitle">Wakasek Urusan Kurikulum<br/>Guru IPA</div>
              </div>
            </div>
          </div>

          {/* Team Item 4 */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="team_item">
              <div className="team_image img-fluid"><img src={Kesiswaan} alt="wakases urusan sarana dan prasarana" /></div>
              <div className="team_body">
                <div className="team_title px-2"><a href="#">Hj. NINA KUSTIANI, S.Pd</a></div>
                <div className="team_subtitle">Wakasek Urusan Kesiswaan<br/>Guru PPKn/IPS</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Profile;
