import React from 'react';
import "../style/main.css"
import "../style/responsive.css"
import RKomputer from "../images/1rkomputer.jpeg";
import RLab from "../images/2lab.jpeg";
import RKelas from "../images/3kelas.jpeg";
import RPerpus from "../images/4perpus.jpeg";
import RMesjid from "../images/5masjid.jpeg";
import RUks from "../images/6UKS.jpeg";
import RWc from "../images/7WC.jpeg";
import Fitnes from "../images/fitnesss.png";

const FeaturesSection = () => {
  return (
    <div className="card feature mb-3">
    <div className="features">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="section_title_container text-center">
              <h2 className="section_title">FASILITAS</h2>
              <div className="section_subtitle">
                
              </div>
            </div>
          </div>
        </div>
        <div className="row features_row">
          {/* Features Item */}
          <div className="col-lg-3 col-md-6 team_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon py-0">
                <img src={RKomputer} alt="Gambar 1" width="300" height="150" />
              </div>
              <div className="feature_text pt-3">
              <h3 className="feature_title">Ruang Komputer</h3>
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
                <img src={RLab} alt="Gambar 2" width="300" height="150"/>
              </div>
              <div className="feature_text pt-3">
              <h3 className="feature_title">Ruang Laboratorium</h3>
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={RKelas} alt="Gambar 3" width="300" height="150"/>
              </div>
              <div className="feature_text pt-3">
              <h3 className="feature_title">Ruang Kelas</h3>
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={RPerpus} alt="Gambar 4" width="300" height="150"/>
              </div>
              <div className="feature_text pt-3">
              <h3 className="feature_title">Ruang Perpustakaan</h3>
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={RMesjid} alt="Gambar 4" width="300" height="150"/>
              </div>
              <div className="feature_text pt-3">
              <h3 className="feature_title">Mesjid Sekolah</h3>
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={RUks} alt="Gambar 4" width="300" height="150"/>
              </div>
              <div className="feature_text pt-3">
              <h3 className="feature_title">Ruang UKS</h3>
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={RWc} alt="Gambar 4" width="300" height="150"/>
              </div>
              <div className="feature_text pt-3">
              <h3 className="feature_title">Toilet</h3>
                
              </div>
            </div>
          </div>
          <div className="col-lg-3 feature_col">
            <div className="feature text-center trans_400">
              <div className="feature_icon">
              <img src={Fitnes} alt="Gambar 4" width="300" height="150"/>
              </div>
              <div className="feature_text pt-3">
              <h3 className="feature_title">Sarana Olah Raga</h3>
                
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </div>
    </div>
  );
};

export default FeaturesSection;
